import all from 'it-all';
import take from 'it-take';

export interface PageCriteria {
  page: {
    page: number;
    pageSize: number;
  };
}

export type PagedAsyncGeneratorOptions<Result> = {
  page?: {
    offset?: number;
    pageSize?: number;
    onPage?: (result: Result) => void;
  };
};

export function pagedAsyncGenerator<
  Criteria extends PageCriteria,
  ResultItem,
  OtherProps extends any[],
>(
  getPaged: (criteria: Criteria, ...otherProps: OtherProps) => Promise<ResultItem[]>,
): (
  criteria: Omit<Criteria, keyof PageCriteria> & PagedAsyncGeneratorOptions<ResultItem[]>,
  ...otherProps: OtherProps
) => AsyncGenerator<ResultItem, void, unknown>;
// eslint-disable-next-line no-redeclare
export function pagedAsyncGenerator<
  Criteria extends PageCriteria,
  Result,
  ResultItem,
  OtherProps extends any[],
>(
  getPaged: (criteria: Criteria, ...otherProps: OtherProps) => Promise<Result>,
  itemsMapper: (result: Result) => ResultItem[],
): (
  criteria: Omit<Criteria, keyof PageCriteria> & PagedAsyncGeneratorOptions<Result>,
  ...otherProps: OtherProps
) => AsyncGenerator<ResultItem, void, unknown>;
// eslint-disable-next-line no-redeclare
export function pagedAsyncGenerator<
  Criteria extends PageCriteria,
  ResultItem,
  OtherProps extends any[],
>(
  getPaged: (criteria: Criteria, ...otherProps: OtherProps) => Promise<ResultItem[]>,
  itemsMapper: (result: unknown) => ResultItem[] = (v) => v as ResultItem[],
): (
  criteria: Omit<Criteria, keyof PageCriteria> & PagedAsyncGeneratorOptions<ResultItem[]>,
  ...otherProps: OtherProps
) => AsyncGenerator<ResultItem, void, unknown> {
  return async function* (criteria, ...otherProps): AsyncGenerator<ResultItem, void, unknown> {
    let currentPage = 1;
    const pageSize = criteria.page?.pageSize || 20;
    let offsetRemaining = criteria.page?.offset || 0;
    while (offsetRemaining >= pageSize) {
      currentPage += 1;
      offsetRemaining -= pageSize;
    }
    const hasMore = true;
    // eslint-disable-next-line no-unmodified-loop-condition
    while (hasMore) {
      const pageInfo: PageCriteria = {
        page: {
          page: currentPage,
          pageSize: pageSize,
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { page: _, ...baseCriteria } = criteria;

      const results = await getPaged(
        {
          ...baseCriteria,
          ...pageInfo,
        } as Criteria,
        ...otherProps,
      );

      const onPage = criteria.page?.onPage;
      if (onPage) {
        onPage(results);
      }

      const resultItems = itemsMapper(results);

      // If there are not enough results to perform offset, end
      if (!resultItems.length || offsetRemaining >= resultItems.length) {
        return;
      }

      // start emitting from offset value
      for (let i = offsetRemaining; i < resultItems.length; i++) {
        yield resultItems[i];
      }

      // If there are less results that page size, it means it's the last page, end
      if (resultItems.length < pageSize) {
        return;
      }

      offsetRemaining = 0;
      currentPage++;
    }
  };
}

/**
 * Return single page from generator crated on endpoints that return totalResult in their page response
 * @param pagedGenerator
 * @param pageParam
 * @param pageSize
 * @param criteria
 * @param otherProps
 */
export async function getSinglePageWithTotalResult<
  Criteria,
  OtherProps extends any[],
  ResultItem,
  Result extends { totalResult: number },
>(
  pagedGenerator: (
    criteria: Criteria & PagedAsyncGeneratorOptions<Result>,
    ...otherProps: OtherProps
  ) => AsyncGenerator<ResultItem, void, unknown>,
  { pageParam, pageSize }: { pageSize: number; pageParam: number },
  criteria: Criteria,
  ...otherProps: OtherProps
) {
  const meta = {
    totalResult: 0,
  };
  const pageParams: PagedAsyncGeneratorOptions<Result> = {
    page: {
      offset: pageSize * (pageParam - 1),
      pageSize: pageSize,
      onPage: (r) => {
        meta.totalResult = r.totalResult;
      },
    },
  };

  const result = await all(
    take(
      pagedGenerator(
        {
          ...criteria,
          ...pageParams,
        },
        ...otherProps,
      ),
      pageSize,
    ),
  );
  return {
    result: result,
    totalResult: meta.totalResult,
  };
}
