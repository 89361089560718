import { PageResultDTO, unwrap as unwrapResult } from 'src/common/models/page';
import {
  AvailabilityStatus,
  CourseEvent,
  EventDTO,
  EventListItemDTO,
  EventUserListItemDTO,
  EventWithAvailabilityStatusDTO,
  mapToCourseEvent,
  mapToEventListItem,
  mapToEventUserListItem,
  UserParticipantStatus,
} from 'src/common/models/event';
import {
  AuthorizedAxios,
  BaseAxios,
  MixedAxios,
  throwErrorResponse,
} from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import { map, pipe } from 'ramda';
import {
  mapToPastCourseListItemDTO,
  PastCourseListItemDTO,
} from 'src/common/models/pastCourseListItem';
import { EventSearchCriteria } from 'src/common/models/eventSearchCriteria';
import { EventCalendarItemDTO, mapToEventCalendarItem } from 'src/common/models/eventCalendarItem';
import {
  getSinglePageWithTotalResult,
  PageCriteria,
  pagedAsyncGenerator,
} from 'src/common/utils/pagedAsyncGenerator';
import { ListWrapper, unwrap } from 'src/lib/listWrapper';

export const autoselectEvent = (
  courseEvents: CourseEvent[],
  eventId?: number,
): CourseEvent | undefined => {
  const courseEvent = eventId ? courseEvents.find((x) => x.id === eventId) : undefined;

  return (
    courseEvent ??
    courseEvents.find(
      (x) =>
        x.availabilityStatus &&
        [
          AvailabilityStatus.Accepted,
          AvailabilityStatus.Pending,
          AvailabilityStatus.Available,
        ].includes(x.availabilityStatus),
    ) ??
    courseEvents?.[0]
  );
};

export const listEventsOnlineGenerator = pagedAsyncGenerator(
  (searchCriteria: EventSearchCriteria & PageCriteria, isLoggedIn: boolean) => {
    const request = isLoggedIn
      ? MixedAxios.post<PageResultDTO<EventListItemDTO>>('api/events/criteria', searchCriteria)
      : BaseAxios.post<PageResultDTO<EventListItemDTO>>(
          'api/public/events/criteria',
          searchCriteria,
        );

    return request.then(flatten).then(({ result, ...rest }) => ({
      result: result.map(mapToEventListItem),
      ...rest,
    }));
  },
  (result) => result.result,
);

export const listEventsOnline = async (
  criteria: EventSearchCriteria,
  pageParam: number,
  pageSize: number,
  isLoggedIn: boolean,
): Promise<{ result: any; totalResult: number }> => {
  return await getSinglePageWithTotalResult(
    listEventsOnlineGenerator,
    { pageSize, pageParam },
    criteria,
    isLoggedIn,
  );
};

export const getEventById = (id: number) =>
  BaseAxios.get<EventDTO>(`/api/events/${id}`).then(pipe(flatten, mapToCourseEvent));

export const getEventsDTOByCourseId = (
  courseId: number,
  isLoggedIn: boolean,
): Promise<Array<EventDTO> | Array<EventWithAvailabilityStatusDTO>> => {
  const request = isLoggedIn
    ? MixedAxios.get<ListWrapper<EventWithAvailabilityStatusDTO>>(
        `/api/events/course/${courseId}/me`,
      )
    : BaseAxios.get<ListWrapper<EventDTO>>(`/api/events/course/${courseId}`);

  return request.then(flatten).then(unwrap).catch(throwErrorResponse);
};

export const getEventsByCourseId = (courseId: number, isLoggedIn: boolean) => {
  const eventsDTO = getEventsDTOByCourseId(courseId, isLoggedIn);
  return eventsDTO.then(map(mapToCourseEvent));
};

export const registerForTheEvent = (eventId: number) =>
  AuthorizedAxios.post(`/api/events/${eventId}/participants`).catch(throwErrorResponse);

export const disenrollFromTheEvent = (eventId: number) =>
  AuthorizedAxios.delete(`/api/events/${eventId}/participants`).catch(throwErrorResponse);

export const getCurrentUserEventParticipantStatus = (eventId: number) =>
  AuthorizedAxios.get<{
    value: UserParticipantStatus;
  }>(`/api/events/${eventId}/participants/me`)
    .then((a) => a.data.value)
    .catch(throwErrorResponse);

export const getCurrentUserCalendarEvents = (page: number, pageSize: number) => {
  return AuthorizedAxios.get<PageResultDTO<EventCalendarItemDTO>>('/api/events/me', {
    params: {
      page,
      pageSize,
    },
  })
    .then(flatten)
    .then(unwrapResult)
    .then(map(mapToEventCalendarItem));
};

export const getCurrentUserIncomingEvents = () =>
  AuthorizedAxios.get<PageResultDTO<EventUserListItemDTO>>(`/api/events/me/incoming`, {
    params: { page: 1, pageSize: 1000 },
  })
    .then(flatten)
    .then((a) => a.result.map(mapToEventUserListItem))
    .catch(throwErrorResponse);

export const getCurrentUserPastEvents = () =>
  AuthorizedAxios.get<PageResultDTO<PastCourseListItemDTO>>(`/api/courses/me/past`, {
    params: { page: 1, pageSize: 1000 },
  })
    .then(flatten)
    .then((a) => a.result.map(mapToPastCourseListItemDTO))
    .catch(throwErrorResponse);

export const getEventRecordings = (eventId: number) =>
  AuthorizedAxios.get<ListWrapper<string>>(`/api/zoom/webinars/event/${eventId}/recording`)
    .then(flatten)
    .then(unwrap)
    .catch(throwErrorResponse);
