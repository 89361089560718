import { CourseEventStatus } from 'src/common/models/event';
import { parseISO } from 'date-fns';
import { coerceUTCDateString } from 'src/lib/coerceUTCDateString';
import { evolve } from 'ramda';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { CourseType } from 'src/common/models/courseType';
import { getCourseSlugRoute } from 'src/course/routes';

export interface EventCalendarItem {
  courseId: number;
  courseTitle: string;
  courseSlug?: string;
  id: number;
  joinUrl: string | null;
  startDateTime: Date;
  status: CourseEventStatus;
}

export interface EventCalendarItemDTO {
  courseId: number;
  courseTitle: string;
  id: number;
  joinUrl: string | null;
  startDateTime: string;
  status: CourseEventStatus;
}

export const mapToEventCalendarItem = (dto: EventCalendarItemDTO): EventCalendarItem =>
  evolve({
    startDateTime: (v: string) => parseISO(coerceUTCDateString(v)),
  })(dto);

export const mapToCourseExcerpt = (eventCalendarItem: EventCalendarItem): CourseExcerpt => ({
  id: eventCalendarItem.id,
  title: eventCalendarItem.courseTitle,
  slug: eventCalendarItem.courseSlug,
  link: getCourseSlugRoute({
    id: eventCalendarItem.courseId,
    slug: eventCalendarItem.courseSlug,
  }),
  courseId: eventCalendarItem.courseId,
  courseType: CourseType.StandardCourse,
  elearningUserEnrollment: null,
});
