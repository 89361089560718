import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const LiveStreamingIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32.0002 14c-9.9411 0-18 8.0589-18 18s8.0589 18 18 18 18-8.0589 18-18-8.0589-18-18-18zm-22 18c0-12.1503 9.8497-22 22-22s22 9.8497 22 22-9.8497 22-22 22-22-9.8497-22-22z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M7.13124 16.3509c-.9109-.6248-2.1558-.3929-2.78057.518C1.62221 20.847 0 26.1935 0 32.0002c0 4.8033 1.10929 9.2819 3.02829 12.9325.40467.7699.84626 1.5046 1.32238 2.1987.62477.9109 1.86967 1.1429 2.78057.5181s1.14286-1.8697.51809-2.7806c-.38417-.5601-.74553-1.1603-1.08041-1.7973C4.97337 40.0362 4 36.2035 4 32.0002c0-5.0951 1.42917-9.6318 3.64933-12.8688.62477-.9109.39281-2.1558-.51809-2.7805z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M56.8691 16.3509c.9109-.6248 2.1558-.3929 2.7806.518 2.7285 3.9781 4.3507 9.3246 4.3507 15.1313 0 4.8033-1.1093 9.2819-3.0283 12.9325-.4047.7699-.8463 1.5046-1.3224 2.1987-.6248.9109-1.8697 1.1429-2.7806.5181-.9109-.6248-1.1428-1.8697-.5181-2.7806.3842-.5601.7456-1.1603 1.0805-1.7973 1.5955-3.0354 2.5689-6.8681 2.5689-11.0714 0-5.0951-1.4292-9.6318-3.6494-12.8688-.6247-.9109-.3928-2.1558.5181-2.7805z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M27.0565 22.2367c.6503-.3481 1.4394-.3099 2.0531.0992l12 8c.5564.3709.8906.9954.8906 1.6641s-.3342 1.2932-.8906 1.6641l-12 8c-.6137.4091-1.4028.4473-2.0531.0993-.6503-.3481-1.0563-1.0258-1.0563-1.7634V24c0-.7376.406-1.4153 1.0563-1.7633zm2.9437 5.5003v8.526L36.3946 32l-6.3944-4.263z"
    />
  </IconBox>
);

export default LiveStreamingIcon;
