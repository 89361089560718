import React, { FC, useEffect, useState } from 'react';
import { BaseButtonProps, Button } from 'src/common/components/Button';
import { useWebinarJoinUrlQuery } from 'src/common/hooks/useWebinarJoinUrlQuery';
import LiveStreamingIcon from 'src/assets/icons/LiveStreamingIcon';
import styled from 'styled-components';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { getCourseTutorialPageRoute } from 'src/course/routes';

const StyledLiveStreamingIcon = styled(LiveStreamingIcon)`
  margin-left: 0.625rem;
`;

export interface JoinWebinarButtonProps extends BaseButtonProps {
  courseLike: { id: number; title: string; slug?: string };
  eventLike: { id: number };
  webinarUrl?: string;
}

export const JoinWebinarButton: FC<JoinWebinarButtonProps> = ({
  courseLike,
  eventLike,
  webinarUrl: initialWebinarUrl,
  ...rest
}) => {
  const [webinarUrl, setWebinarUrl] = useState<string | undefined>(initialWebinarUrl);

  const { data: downloadedWebinarUrl } = useWebinarJoinUrlQuery(eventLike.id, {
    enabled: webinarUrl == null,
    refetchInterval: 30 * 1000,
  });

  useEffect(() => {
    setWebinarUrl(initialWebinarUrl);
  }, [courseLike, eventLike, initialWebinarUrl]);

  useEffect(() => {
    setWebinarUrl(downloadedWebinarUrl?.url);
  }, [downloadedWebinarUrl]);

  useEffect(() => {
    setWebinarUrl(initialWebinarUrl);
  }, [initialWebinarUrl]);

  if (webinarUrl == null) {
    return <></>;
  }

  return (
    <Button
      as={GatsbyLink}
      to={getCourseTutorialPageRoute(eventLike, courseLike)}
      kind="primary"
      size="tiny"
      {...rest}
    >
      <span style={{ verticalAlign: 'middle' }}>Dołącz teraz</span>
      <StyledLiveStreamingIcon size={rest.size === 'default' ? 19 : 16} />
    </Button>
  );
};
