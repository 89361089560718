import { AuthorizedAxios, throwErrorResponse } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import { UrlDTO } from 'src/common/models/url';
import { isNilEmpty } from 'src/lib/isNilEmpty';

export const getWebinarJoinUrl = (eventId: number) =>
  AuthorizedAxios.get<UrlDTO>(`/api/zoom/webinars/event/${eventId}/join`)
    .then(flatten)
    .catch(throwErrorResponse)
    .then((a) => {
      if (isNilEmpty(a.url)) {
        throw Error('Webinar join URL was empty');
      }

      return a;
    });
