import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { getWebinarJoinUrl } from 'src/common/services/zoom';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';

export const useWebinarJoinUrlQuery = (
  eventId: number,
  options?: { enabled?: boolean; refetchInterval?: number },
) => {
  const { key, isAuthenticated } = useCurrentUserKeyEnhancer([QueryKey.WebinarJoinUrl, eventId]);

  return useQuery(key, () => getWebinarJoinUrl(eventId), {
    cacheTime: 0,
    enabled: (options?.enabled ?? true) && isAuthenticated,
    refetchInterval: options?.refetchInterval,
  });
};
