import { parseISO } from 'date-fns';
import { coerceUTCDateString } from 'src/lib/coerceUTCDateString';
import { evolve, map } from 'ramda';
import { mapToSpeaker, Speaker, SpeakerDTO } from 'src/common/models/speaker';
import { SurveyStatus } from 'src/common/models/survey';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { pipe } from 'fp-ts/function';
import { fromNullable, map as mapOption, toUndefined } from 'fp-ts/Option';
import { RecordingStatus } from 'src/common/models/event';
import { CourseType } from 'src/common/models/courseType';
import { ElearningUserEnrollment } from 'src/common/models/elearning';
import { getElearningRoute } from 'src/elearning/routes';
import { getCourseEventSlugRoute } from 'src/course/routes';
import { StoredImageDTO } from './image';

export interface PastCourseListItem {
  courseDateTime: Date;
  courseType: CourseType;
  duration: number;
  elearningUserEnrollment?: ElearningUserEnrollment;
  eventId: number;
  id: number;
  image: StoredImageDTO;
  joinUrl: string;
  recordingAvailableUntil: Date | null;
  recordingStatus: RecordingStatus | null;
  speakers: Speaker[];
  surveyAvailableUntil: Date;
  surveyStatus: SurveyStatus;
  title: string;
  slug?: string;
}

export interface PastCourseListItemDTO {
  courseDateTime: string;
  courseType: CourseType;
  duration: number;
  elearningUserEnrollment?: ElearningUserEnrollment;
  eventId: number;
  id: number;
  image: StoredImageDTO;
  joinUrl: string;
  recordingAvailableUntil: string | null;
  recordingStatus: RecordingStatus | null;
  speakers: SpeakerDTO[];
  surveyAvailableUntil: string;
  surveyStatus: SurveyStatus;
  title: string;
  slug?: string;
}

export const mapToCourseExcerpt = (pastCourse: PastCourseListItem): CourseExcerpt => ({
  courseId: pastCourse.id,
  courseType: pastCourse.courseType,
  date: pastCourse.courseDateTime,
  duration: pastCourse.duration,
  elearningUserEnrollment: pastCourse.elearningUserEnrollment
    ? {
        farthestLessonId: null,
        farthestPointInSeconds: null,
        isEnrolled: pastCourse.elearningUserEnrollment.isEnrolled,
      }
    : null,
  id: pastCourse.eventId,
  image: pastCourse.image,
  title: pastCourse.title,
  slug: pastCourse.slug,
  link:
    pastCourse.courseType === CourseType.StandardCourse
      ? getCourseEventSlugRoute({ id: pastCourse.eventId }, pastCourse)
      : getElearningRoute(pastCourse.elearningUserEnrollment ?? null, pastCourse),
  speaker: pipe(
    fromNullable(pastCourse.speakers[0]),
    mapOption((a) => ({
      id: a.id,
      firstName: a.firstName,
      lastName: a.lastName,
      companyName: a.company,
    })),
    toUndefined,
  ),
});

export const mapToPastCourseListItemDTO = (
  pastCourse: PastCourseListItemDTO,
): PastCourseListItem => {
  return evolve({
    courseDateTime: (v) => parseISO(coerceUTCDateString(v)),
    recordingAvailableUntil: (v) => (v ? parseISO(coerceUTCDateString(v)) : v),
    surveyAvailableUntil: (v) => parseISO(coerceUTCDateString(v)),
    speakers: map(mapToSpeaker),
  })(pastCourse);
};
