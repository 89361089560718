import { format as dateFnsFormat, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import { appLocale } from 'src/config/locale';
import { coerceUTCDateString } from 'src/lib/coerceUTCDateString';
import { is } from 'ramda';

const locales = {
  'pl-PL': pl,
};

export const format = (date: Date | number | string, format: string) =>
  dateFnsFormat(is(String, date) ? parseISO(coerceUTCDateString(date)) : date, format, {
    locale: locales[appLocale],
  });

export const parseISODateIfExist = (date?: string) =>
  date ? parseISO(coerceUTCDateString(date)) : undefined;
